import * as Yup from 'yup';
import {
  Stack,
  Typography,
  Box,
  Divider,
  Button,
  Skeleton,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import React, { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormProvider,
  RHFDatePicker,
  RHFRadioGroup,
  RHFTextField,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import useAuth from '#/hooks/useAuth';
import {
  invalidateChildrenQueries,
  useAddChild,
  useDeleteChild,
  useUpdateChild,
} from '#/api/childrenQueries';
import Iconify from '#/components/shared/ui/Iconify';
import DeleteConfirmationModal from '#/components/pages/LivingWill/Steps/content/Representatives/DeleteConfirmationModal';
import { fDate, fToDB } from '#/utils/formatTime';

type ChildDetailProps = {
  child: {
    id: number | string;
    first_name: string;
    last_name: string;
    date_of_birth: string;
    from_commonMarriage: string;
  };
  livingPartnership?: string;
  isLoading?: boolean;
  sx?: any;
};

export const ChildDetail = ({
  child,
  livingPartnership,
  isLoading,
  sx,
}: ChildDetailProps) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { translate } = useLocales();

  const handleOpenDeleteDialog = () => setOpenDeleteDialog(true);

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const { id, first_name, last_name, date_of_birth } = child;

  const { mutateAsync: deleteChild } = useDeleteChild(child.id);

  const handleDelete = async () => {
    await deleteChild(id)
      .then(() => {
        toast.success(
          String(translate('toast_notifications.success.childDelete'))
        );
        invalidateChildrenQueries.getChildren();
      })
      .catch(() => {
        toast.error(String(translate('toast_notifications.error.childDelete')));
      });
  };

  const handleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  return (
    <Box sx={{ width: 1, marginY: 1.5 }}>
      {editMode ? (
        <ChildForm
          child={child}
          isEdit
          handleEditMode={handleEditMode}
          isLivingPartnership={livingPartnership}
        />
      ) : (
        <Box
          sx={{
            my: 0.5,
            ...sx,
            display: 'flex',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            justifyContent: 'space-between',
            alignItems: {
              xs: 'flex-start',
              sm: 'center',
            },
            // p: 2,
          }}
        >
          <Stack>
            <Typography variant="subtitle1">
              {isLoading ? (
                <Skeleton variant="text" width={100} />
              ) : (
                `${first_name} ${last_name}`
              )}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },
                justifyContent: 'space-between',
                alignItems: {
                  xs: 'flex-start',
                  sm: 'center',
                },
              }}
            >
              <Stack>
                <Typography variant="body2">
                  {String(translate('lastWill.childBirthDate'))}:{' '}
                  <span style={{ fontWeight: 600, fontSize: 16 }}>
                    {fDate(date_of_birth, 'dd. MMM yyyy', 'de')}
                  </span>
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="outlined"
                    onClick={handleOpenDeleteDialog}
                    color="error"
                    size="small"
                    startIcon={
                      <Iconify
                        icon="octicon:trash-16"
                        sx={{ fontSize: '1.5rem' }}
                      />
                    }
                  >
                    <Typography variant="subtitle2" fontWeight="light">
                      {String(translate('global.delete'))}
                    </Typography>
                  </Button>

                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={
                      <Iconify
                        icon="octicon:pencil-16"
                        sx={{ fontSize: '1.5rem' }}
                      />
                    }
                    onClick={handleEditMode}
                  >
                    <Typography variant="subtitle2" fontWeight="light">
                      {String(translate('global.edit'))}
                    </Typography>
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Box>
      )}
      <DeleteConfirmationModal
        openDeleteDialog={openDeleteDialog}
        handleCloseDeleteDialog={handleCloseDeleteDialog}
        handleDelete={handleDelete}
      />
    </Box>
  );
};

type Props = {
  child?: {
    id: string | number;
    first_name: string;
    last_name: string;
    date_of_birth: string;
    from_commonMarriage: string;
  };
  isEdit?: boolean;
  handleEditMode?: () => void;
  handleAddMore?: () => void;
  handleLastAdded?: React.Dispatch<React.SetStateAction<string | null>>;
  isLivingPartnership?: string;
  hideQuestion?: boolean;
};

export const ChildForm = ({
  child,
  isEdit,
  handleEditMode,
  handleAddMore,
  handleLastAdded,
  isLivingPartnership,
  hideQuestion,
}: Props) => {
  const { translate } = useLocales();
  const { user } = useAuth();

  const isSingle = user?.civil_status === 'single';
  const isMarried = user?.civil_status === 'married';
  const isDivorced = user?.civil_status === 'divorced';
  const isWidowed = user?.civil_status === 'widowed';
  const isInRegisteredPartnership =
    user?.civil_status === 'registered_partnership';

  const singleWithNoPartnership =
    (isSingle || isDivorced || isWidowed) && isLivingPartnership === 'no';

  const { mutateAsync: addChild, isLoading: addLoading } = useAddChild();
  const { mutateAsync: updateChild, isLoading: updateLoading } = useUpdateChild(
    child?.id
  );

  const Schema = Yup.object().shape({
    first_name: Yup.string().required(
      String(translate('validations.user.first_name'))
    ),
    last_name: Yup.string().required(
      String(translate('validations.user.last_name'))
    ),
    date_of_birth: Yup.date().required(
      String(translate('validations.user.date_of_birth.required'))
    ),
  });

  const defaultValues = useMemo(
    () => ({
      first_name: child?.first_name || '',
      last_name: child?.last_name || '',
      date_of_birth: child?.date_of_birth || null,
      from_commonMarriage: singleWithNoPartnership
        ? 'no'
        : child?.from_commonMarriage || '',
    }),
    [child, singleWithNoPartnership]
  );

  const methods = useForm({
    resolver: yupResolver(Schema) as any,
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    formState: { isDirty },
  } = methods;

  const onSubmit = async (data: any) => {
    const reqData = {
      ...data,
      date_of_birth: fToDB(data.date_of_birth),
    };
    if (isEdit) {
      await updateChild(reqData)
        .then(() => {
          toast.success(
            String(translate('toast_notifications.success.childEdit'))
          );
          if (handleEditMode) handleEditMode();
          invalidateChildrenQueries.getChildren();
        })
        .catch(() => {
          toast.error(String(translate('toast_notifications.error.childEdit')));
        });
    } else {
      await addChild(reqData)
        .then((res) => {
          toast.success(
            String(translate('toast_notifications.success.childAdd'))
          );
          if (handleAddMore) handleAddMore();
          if (handleLastAdded) handleLastAdded(res.data.id);
          invalidateChildrenQueries.getChildren();
        })
        .catch(() => {
          toast.error(String(translate('toast_notifications.error.childAdd')));
        });
    }
  };

  const isSubmitDisabled =
    (isEdit && JSON.stringify(defaultValues) === JSON.stringify(watch())) ||
    (!isEdit && !isDirty);

  useEffect(() => {
    if (isEdit || singleWithNoPartnership) {
      methods.reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [isEdit, singleWithNoPartnership]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} sx={{ marginTop: 2 }}>
        {/* <Typography variant="body1"> */}
        {/*  {String( */}
        {/*    translate('global.formLabels.ordinalNumbers.information', { */}
        {/*      ordinalNumber: ordinalNumber(ordinalNr, translate), */}
        {/*    }) */}
        {/*  )} */}
        {/* </Typography> */}
        <Stack direction="row" spacing={2}>
          <RHFTextField
            name="first_name"
            label={String(translate('global.formLabels.firstName'))}
          />
          <RHFTextField
            name="last_name"
            label={String(translate('global.formLabels.lastName'))}
          />
        </Stack>
        <RHFDatePicker
          name="date_of_birth"
          disableFuture
          label={String(translate('global.formLabels.date_of_birth'))}
        />
        {!hideQuestion && (
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body2">
              {isSingle &&
                isLivingPartnership === 'yes' &&
                String(
                  translate('lastWill.stepOne.isChildFromCommonRelationship')
                )}
              {(isMarried || isInRegisteredPartnership) &&
                String(translate('lastWill.stepOne.isChildFromCommonMarriage'))}
              {(isDivorced || isWidowed) &&
                isLivingPartnership === 'yes' &&
                String(
                  translate('lastWill.stepOne.isChildFromCommonRelationship')
                )}
            </Typography>
            <RHFRadioGroup
              options={[
                { label: String(translate('lastWill.yes')), value: 'yes' },
                { label: String(translate('lastWill.no')), value: 'no' },
              ]}
              name="from_commonMarriage"
              sx={{
                flexDirection: 'row',
                display: singleWithNoPartnership ? 'none' : 'flex',
              }}
            />
          </Stack>
        )}
        <Stack direction="row" spacing={2} alignItems="center">
          <LoadingButton
            variant="contained"
            size="small"
            color="secondary"
            sx={{
              width: '120px',
            }}
            startIcon={<Iconify icon="mdi:content-save" />}
            onClick={() => handleSubmit(onSubmit)()}
            loading={updateLoading || addLoading}
            disabled={isSubmitDisabled}
          >
            <Typography variant="caption">
              {String(translate('global.save'))}
            </Typography>
          </LoadingButton>
          {(handleAddMore || isEdit) && (
            <Button
              size="small"
              variant="outlined"
              onClick={handleAddMore || handleEditMode}
              sx={{ marginLeft: 2 }}
              startIcon={<Iconify icon="mdi:close" />}
            >
              <Typography variant="caption">
                {String(translate('global.cancel'))}
              </Typography>
            </Button>
          )}
        </Stack>
        <Divider />
      </Stack>
    </FormProvider>
  );
};
