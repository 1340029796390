import { Box, Button, Card, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useGetChildren } from '#/api/childrenQueries';
import {
  ChildDetail,
  ChildForm,
} from '#/components/pages/LastWill/steps/content/Inheritors/childrens/HaveChildrens';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';

type Props = {
  isLivingPartnership: string;
  hideQuestion?: boolean;
};

export default function Child({ isLivingPartnership, hideQuestion }: Props) {
  const [addMore, setAddMore] = useState(false);
  const { data: children, isLoading } = useGetChildren();
  const { translate } = useLocales();

  const checkDuplicate = () => {
    if (children && children?.length > 1) {
      return children?.some((child, index) => {
        return children?.some(
          (child2, index2) =>
            index !== index2 &&
            child.first_name === child2.first_name &&
            child.last_name === child2.last_name
        );
      });
    }
    return false;
  };

  const handleAddMore = () => {
    setAddMore((prev) => !prev);
  };

  const isInitial = children?.length === 0;

  return (
    <Card
      sx={{
        m: 1,
        p: 2,
      }}
    >
      {isInitial ? (
        <ChildForm
          isLivingPartnership={isLivingPartnership}
          hideQuestion={hideQuestion}
        />
      ) : (
        <Box>
          {!addMore ? (
            <Stack>
              {children?.map((child) => (
                <ChildDetail
                  key={child.id}
                  child={child}
                  isLoading={isLoading}
                  livingPartnership={isLivingPartnership}
                />
              ))}
              <Stack direction="row" alignItems="center">
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  sx={{
                    width: '320px',
                    my: 2,
                  }}
                  startIcon={<Iconify icon="mdi:plus-circle" />}
                  onClick={handleAddMore}
                >
                  <Typography variant="caption">
                    {String(translate('lastWill.addAnotherChildren'))}
                  </Typography>
                </Button>
                {checkDuplicate() && (
                  <Button
                    startIcon={<Iconify icon="ion:warning-sharp" />}
                    color="error"
                  >
                    <Typography>
                      {String(translate('validations.duplicateChild'))}
                    </Typography>
                  </Button>
                )}
              </Stack>
            </Stack>
          ) : (
            <ChildForm
              handleAddMore={handleAddMore}
              isLivingPartnership={isLivingPartnership}
              hideQuestion={hideQuestion}
            />
          )}
        </Box>
      )}
    </Card>
  );
}
